<template>
  <v-autocomplete
    v-model="ocorrencia"
    auto-select-first
    dense
    outlined
    return-object
    no-filter
    label="Ocorrência"
    :search-input.sync="pesquisa"
    :items="ocorrencias"
    item-text="descricao"
    item-value="id"
    :loading="carregando"
    :error-messages="errorMessages"
    placeholder="Pesquise pela descrição"
    autocomplete="off"
    hide-details="auto"
    @change="ocorrenciaSelecionada"
  >
    <template
      slot="selection"
      slot-scope="data"
    >
      {{ data.item.descricao }}
    </template>
    <template
      slot="item"
      slot-scope="data"
    >
      {{ data.item.id }} - {{ data.item.descricao }}
    </template>
  </v-autocomplete>
</template>

<script>
  import ocorrenciasApi from '@/api/ocorrencias'

  export default {
    props: {

      errorMessages: {
        type: Array,
        default: () => {},
      },
      limparAposSelecionar: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        ocorrencia: {},
        ocorrencias: [],
        carregando: false,
        pesquisa: '',
      }
    },

    watch: {
      pesquisa () {
        this.buscarOcorrencias()
      },
    },

    methods: {
      async buscarOcorrencias () {
        if (this.pesquisa === null || this.pesquisa.length <= 0) return

        try {
          this.carregando = true
          const resposta = await ocorrenciasApi.listar({
            descricao: this.pesquisa,
          })
          this.ocorrencias = resposta.data.data
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

      async ocorrenciaSelecionada () {
        const ocorrencia = { ...this.ocorrencia }

        if (this.limparAposSelecionar) {
          this.pesquisa = ''
          this.ocorrencia = null
          this.ocorrencias = []
        }

        this.$emit('change', ocorrencia)
      },
    },
  }
</script>
